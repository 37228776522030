<template>
  <div>
    <common-nav :module="0" />
    <div class="content-wrap">
      <div class="block no-shadow">
        <el-row :gutter="20">
          <el-col :xs="24" :md="12">
            <div class="carousel">
              <el-carousel :height="screenWidth<=1024?'220px':'440px'" :loop="true" arrow="always">
                <el-carousel-item v-for="(item, index) in topping" :key="index">
                  <img :src="item.image" :alt="item.title" @click="handleClick" :data-id="item.id">
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
          <el-col :xs="24" :md="12">
            <div class="tabs">
              <el-tabs v-model="activeName">
                <el-tab-pane label="实验室工作动态" name="first">
                  <article-list :category="1" :tab="true"  />
                </el-tab-pane>
                <el-tab-pane label="安全评估" name="second">
                  <article-list :category="2" :tab="true"  />
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="block no-shadow">
        <el-row :gutter="20">
          <el-col :xs="24" :md="12">
            <article-list :category="3" label="DRM服务认证" :more="true"  />
          </el-col>
          <el-col :xs="24" :md="12">
            <article-list :category="4" label="资源下载" :more="true"  />
          </el-col>
        </el-row>
      </div>
    </div>
    <common-footer />
  </div>
</template>

<script>
/* eslint-disable */
import CommonNav from '@/components/CommonNav.vue'
import CommonFooter from '../components/CommonFooter.vue'
import ArticleList from './components/ArticleList.vue'
export default {
  name: 'HomeView',
  components: { CommonNav, CommonFooter, ArticleList },
  data () {
    return {
      activeName: 'first',
      topping: [],
      screenWidth: 0,
    }
  },
  created () {
    this.fetchTopping()
  },
  mounted() {
    this.screenWidth = window.innerWidth
    this.monitorScreenSize()
  },
  methods: {
    monitorScreenSize() {
      window.addEventListener('resize', this.handleResize)
    },
    handleResize() {
      this.screenWidth = window.innerWidth
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
    fetchTopping() {
      this.$http.get('/web', { params: { module: 'topping', categoryId: 0 } }).then(res => {
        this.topping = res
      })
    },
    handleClick(e) {
      this.$router.push({ path: `/article/${e.target.dataset.id}`, target: '_blank' })
    }
  }
}
</script>
<style>
.el-tabs__item {
  font-size: 26px;
}
.el-tabs__nav {
  padding-bottom: 15px;
}
.el-tabs__item.is-active {
  color: rgba(223, 48, 32, 1);
}
.el-tabs__active-bar {
  background-color: rgba(223, 48, 32, 1);
}
@media (max-width: 960px) {
  .el-tabs__item {
    font-size: 18px;
  }
}
</style>
