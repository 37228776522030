<template>
  <div class="footer-wrap">
    <div class="footer">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="10">
          <div class="con-wrap">
            <div class="left">友情链接</div>
            <div class="right">
              <ul>
                <li><a href="https://www.nrta.gov.cn" target="_blank">国家广播电视总局</a></li>
                <li><a href="http://www.abs.ac.cn" target="_blank">国家广播电视总局广播电视科学研究院</a></li>
                <li><a href="http://www.china-drm.net" target="_blank">DRM论坛</a></li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="10">
          <div class="con-wrap">
            <div class="left">版权信息</div>
            <div class="right">
              <ul>
                <li>版权所有：国家广播电视总局广播电视科学研究院</li>
                <li>主办单位地址：北京市西城区复兴门外大街2号</li>
                <li><a href="https://beian.miit.gov.cn" target="_blank">ICP证：京ICP备17003827号-1</a></li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="4">
          <div class="con-wrap vertical center">
            <img :src="qrcode" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import qrcode from '@/assets/qrcode.jpeg'
export default {
  name: 'CommonFooter',
  data() {
    return {
      qrcode: qrcode
    }
  },
  created() {
  }
}
</script>
<style lang="scss" scoped>
</style>