import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import '@/styles/index.scss' // global css
import App from './App.vue'
import router from './router'
import store from './store'
import request from './utils/request'
import * as filters from './filters'
import '@/permission'

Vue.use(ElementUI)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// Vue.config.errorHandler = function(err, vm, info) {
//   console.log(err, vm, info)
// }

// Vue.config.warnHandler = function (msg, vm, trace) {
//   // 处理警告
//   console.warn('Vue 全局警告:', msg, trace);
//   // 可以将警告信息发送到后端服务器
//   // sendToServer(msg, trace);
// }

Vue.config.productionTip = false
Vue.prototype.$http = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
