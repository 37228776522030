/* eslint-disable */
import request from '@/utils/request'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
  token: getToken(),
  name: '',
  avatar: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      request({
        url: '/web/login',
        method: 'post',
        data: { username: username.trim(), password: password }
      }).then(response => {
        const { accessToken } = response
        commit('SET_TOKEN', accessToken)
        setToken(accessToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  register ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      request({
        url: '/web/register',
        method: 'post',
        data: userInfo
      }).then(res => {
        if (res) {
          resolve()
        } else {
          reject('注册失败')
        }
      }).catch(error => reject(error))
    })
  },

  getInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: '/web/info',
        method: 'get'
      }).then(res => {
        const user = res
        commit('SET_NAME', user.username)
        commit('SET_AVATAR', user.avatar)
        resolve(user)
      }).catch(error => reject(error))
    })
  },

  // user logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_NAME', '')
      commit('SET_AVATAR', '')
      commit('SET_TOKEN', '')
      removeToken()

      resolve()
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
